import { Row, Col, Typography, Divider } from "antd";
// import Background from '../../assets/WhatIsOpineBG.png'
import phone from '../../assets/Phone_2.png'
import AppStoreBadge from '../../assets/Download_on_the_App_Store_Badge.svg'

export default function WhatIsOpineSection(){
  return(
    <section
      style={{
        // padding:'50px 0 0 50px',
        // backgroundImage:`url(${Background}`,
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'right',
        // backgroundSize: 'cover',
        display: 'flex',
        justifyContent:'center',
        paddingTop:30,
        paddingLeft:50,
        // paddingRight:50,
        paddingBottom:50,
      }}
    >
      <Row 
        justify='center'
        align='middle'
        style={{
          maxWidth:2200,
          overflow:'hidden'
        }}
      >
        <Col 
          xs={24} 
          sm={{span:18, offset: 2}} 
          md={18} 
          lg={{span:9, offset:0}} 
          xl={{span:7, offset:0}} 
          // xxl={{span:6, offset:2}}
          >
          <Row>
            <Col>
              <Typography.Title 
                style={{
                  textTransform:'uppercase'
                }}>
                What is Opine
              </Typography.Title>
            </Col>

            <Col>
              <Typography.Title 
                level={2} 
                style={{
                  fontSize:24, 
                  fontWeight:200, 
                  opacity:0.5
                }}>
                Opine is the leading social, real-time opinion analytics platform for live events. We deliver a new way to create, display and consume media during the micromoments of an event. 
              </Typography.Title>
            </Col>

            <Col span={4}>
              <Divider 
                style={{
                  borderColor:'red',
                  padding:0
                }}/>
            </Col>

            <Col>
              <Typography.Text 
                type='secondary' 
                style={{
                  opacity:0.7, 
                  fontWeight:300
                }}>
                Opine users do.more.live as they express opinions at the speed of the event, both in private groups and among the masses.  
              </Typography.Text>
            </Col>

            <Col style={{marginTop:20, zIndex:10}}>
              <a href='https://apps.apple.com/us/app/opine-live/id1299500673' target="_blank" rel="noreferrer"><img src={AppStoreBadge} alt='Download on the App Store' /></a>
            </Col>

            {/* <Col>
              <Button 
                shape='round' 
                size='large' 
                disabled
                style={{
                  // borderColor:'red', 
                  // color:'red', 
                  marginTop:30
                }}>
                  Coming Soon!
              </Button>
            </Col> */}
          </Row>
        </Col>

        <Col
          xs={{span: 24, offset:0}} 
          sm={{span:24, offset:0}} 
          md={{span:24, offset:0}} 
          lg={10} 
          xl={11} 
          // xxl={12} 
          style={{ 
            display:'flex', 
            justifyContent:'flex-start', 
            marginTop:50,
            maxHeight:900
            }}>
          <img src={phone} alt='Opine App Preview' style={{height:1000}}/>
        </Col>
      </Row>
    </section>
  )
}