import React, { useState } from 'react'
import { Row, Form, Card } from 'antd'
import {Dante} from 'dante3';

export default function ExtraAppsWordpressAdd (){



  // console.log('editorState', editorState)

  return (
    <Row style={{padding:20}}>
      <Dante 
        content={'hello'}
      />
    </Row>
  )
}