import { Col, Row, Typography } from "antd";

export default function PrivacyPolicy(){
  return(
    <Row gutter={[0,16]} style={{ maxWidth:1200, margin:'0 auto', padding: 50}}>
      <Col span={24}>
        <Typography.Title>
          Privacy Policy
        </Typography.Title>
        </Col>

        <Typography.Text>
          Protecting your private information is our priority. This Statement of Privacy applies to https://opinelive.com/, Opine Inc., and the Opine app (Opine Tools) and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Opine Tools. The Opine website and app are data analytics platforms. By using the Opine Tools, you consent to the data practices described in this statement. 
        </Typography.Text>

      <Col span={24}>
        <Typography.Title level={4}>
          Collection of your Personal Information 
        </Typography.Title>
      </Col>

      <Typography.Text>
        Protecting your private information is our priority. This In order to better provide you with products and services offered on our Tools, Opine may collect personally identifiable information, such as your:  
      </Typography.Text>

      <Col span={24}>
        <ul>
          <li>First and Last Name</li>
          <li>Mailing Address</li>
          <li>E-mail Address</li>
          <li>Phone Number</li>
          <li>Social media profiles connected to Opine</li>
        </ul>
      </Col>

      <Typography.Text>
        Opine may also collect anonymous demographic information, which is not unique to you, such as your: 
      </Typography.Text>

      <Col span={24}>
        <ul>
          <li>Age</li>
          <li>Gender</li>
        </ul>
      </Col>

      <Typography.Text>
        Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Opine's public message boards, this information may be collected and used by others. 
      </Typography.Text>

      <Typography.Text>
        We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future. 
      </Typography.Text>

      <Col span={24}>
      <Typography.Title level={4}>
        Use of your Personal Information 
      </Typography.Title>
      </Col>

      <Typography.Text>
        Opine collects and uses your personal information to operate itsTools and deliver the services you have requested. 
      </Typography.Text>

      <Typography.Text>
        Opine may also use your personally identifiable information to inform you of other products or services available from Opine and its affiliates. 
      </Typography.Text>

      <Col span={24}>
      <Typography.Title level={4}>
        Sharing Information with Third Parties  
      </Typography.Title>
      </Col>

      <Typography.Text>
        Opine does not sell, rent or lease its customer lists to third parties.
      </Typography.Text>

      <Typography.Text>
        Opine may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third party. Opine may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Opine, and they are required to maintain the confidentiality of your information. 
      </Typography.Text>
  
      <Typography.Text>
        Opine may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Opine or the site; (b) protect and defend the rights or property of Opine; and/or (c) act under exigent circumstances to protect the personal safety of users of Opine, or the public. 
      </Typography.Text>

      <Col span={24}>
      <Typography.Title level={4}>
        Opt-Out of Disclosure of Personal Information to Third Parties
      </Typography.Title>
      </Col>

      <Typography.Text>
        In connection with any personal information we may disclose to a third party for a business purpose, you have the right to know: 
      </Typography.Text>

      <Col span={24}>
      <ul>
        <li>
          The categories of personal information that we disclosed about you for a business purpose. 
        </li>
      </ul>
      </Col>

      <Typography.Text>
        You have the right under the California Consumer Privacy Act of 2018 (CCPA) and certain other privacy and data protection laws, as applicable, to opt-out of the disclosure of your personal information. If you exercise your right to opt-out of the disclosure of your personal information, we will refrain from disclosing your personal information, unless you subsequently provide express authorization for the disclosure of your personal information. To opt-out of the disclosure of your personal information, send an email to opt-out@opineinc.com.  
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Tracking User Behavior 
      </Typography.Title>
      </Col>

      <Typography.Text>
        Opine may keep track of the websites and pages our users visit within Opine, in order to determine what Opine services are the most popular. This data is used to deliver customized content and advertising within Opine to customers whose behavior indicates that they are interested in a particular subject area. 
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Automatically Collected Information
      </Typography.Title>
      </Col>

      <Typography.Text>
        Information about your computer hardware and software may be automatically collected by Opine. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Opine website. 
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Use of Cookies
      </Typography.Title>
      </Col>

      <Typography.Text>
        The Opine website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. 
      </Typography.Text>
  
      <Typography.Text>
        One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Opine pages, or register with Opine site or services, a cookie helps Opine to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Opine website, the information you previously provided can be retrieved, so you can easily use the Opine features that you customized. 
      </Typography.Text>
  
      <Typography.Text>
        You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Opine services or websites you visit. 
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Links
      </Typography.Title>
      </Col>

      <Typography.Text>
        This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information. 
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Security of your Personal Information 
      </Typography.Title>
      </Col>

      <Typography.Text>
        Opine secures your personal information from unauthorized access, use, or disclosure. Opine uses the following methods for this purpose: 
      </Typography.Text>

      <Col span={24}>
      <ul>
        <li>
          SSL Protocol
        </li>
      </ul>
      </Col>

      <Typography.Text>
        When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. 
      </Typography.Text>

      <Typography.Text>
        We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. 
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Right to Deletion 
      </Typography.Title>
      </Col>

      <Typography.Text>
        Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: 
      </Typography.Text>

      <Col span={24}>
      <ul>
        <li>
          Delete your personal information from our records; and 
        </li>
        <li>
          Direct any service providers to delete your personal information from their records. 
        </li>
      </ul>
      </Col>

      <Typography.Text>
        Please note that we may not be able to comply with requests to delete your personal information if it is necessary to: 
      </Typography.Text>

      <Col span={24}>
      <ul>
        <li>
          Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;
        </li> 
        <li>
          Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity; 
        </li>
        <li>
          Debug to identify and repair errors that impair existing intended functionality; 
        </li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law; 
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act; 
        </li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent; 
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; 
        </li>
        <li>
          Comply with an existing legal obligation; or 
        </li>
        <li>
          Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information. 
        </li>
      </ul>
      </Col>

      <Col span={24}>

      <Typography.Title level={4}>
        Children Under Thirteen 
      </Typography.Title>
      </Col>

      <Typography.Text>
        Opine does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. 
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Opt-Out & Unsubscribe from Third Party Communications 
      </Typography.Title>
      </Col>

      <Typography.Text>
        We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from third-party partners of Opine by contacting us here:  
      </Typography.Text>

      <Col span={24}>
        <ul>
          <li>
            Email: opt-out@opineinc.com 
          </li>
        </ul>
      </Col>

      <Col span={24}>

      <Typography.Title level={4}>
        E-mail Communications 
      </Typography.Title>
      </Col>

      <Typography.Text>
        From time to time, Opine may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Opine or click on a link therein. 
      </Typography.Text>
  
      <Typography.Text>
        If you would like to stop receiving marketing or promotional communications via email from Opine, you may opt out of such communications by clicking on the UNSUBSCRIBE button.
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        External Data Storage Sites 
      </Typography.Title>
      </Col>

      <Typography.Text>
        We may store your data on servers provided by third party hosting vendors with whom we have contracted. 
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Changes to this Statement 
      </Typography.Title>
      </Col>

      <Typography.Text>
        Opine reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.  
      </Typography.Text>

      <Col span={24}>

      <Typography.Title level={4}>
        Contact Information 
      </Typography.Title>
      </Col>

      <Typography.Text>
        Opine welcomes your questions or comments regarding this Statement of Privacy. If you believe that Opine has not adhered to this Statement, please contact Opine at:  
      </Typography.Text>

      <Col span={24}>

      <address>
        Opine Inc. 
        815 W 1250 S Suite 115 
        Orem, Utah 84058-5986 
      </address>
      </Col>

      <Col span={24}>


      <Typography.Text>
        Email Address: 
      </Typography.Text>
      </Col>

      <Col span={24}>

      <Typography.Link href='mailto:info@opineinc.com'>
        info@opineinc.com 
      </Typography.Link>
      </Col>

      <Col span={24}>

      <Typography.Text>
        Telephone number: 
      </Typography.Text>
      </Col>

      <Col span={24}>

      <Typography.Link href='tele:8014721272'>
        801.472.1272 
      </Typography.Link>
      </Col>

      <Col span={24}>

      <Typography.Text>
        Effective as of November 16, 2020 
      </Typography.Text>

      </Col>
    </Row>
  )
}