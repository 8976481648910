import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Contact from './pages/Contact'
import Home from './pages/Home/Home'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import DanteEditor from './pages/DanteEditor/DanteEditor'
// import SignupSuccess from './pages/SignupSuccess/SignupSuccess'
// import Test from './pages/Test.js'

export default (
  <Switch>
    <Route exact path='/' component={Home}/>
    <Route path='/privacy-policy' component={PrivacyPolicy}/>
    <Route path='/contact' component={Contact}/>
    <Route path='/blog/admin/add-post' component={DanteEditor}/>
    {/* <Route path='/signup-success' component={SignupSuccess}/> */}
    {/* <Route path='/test' component={Test}/> */}
  </Switch>
)