import { Row, Col, Typography } from "antd";
import WebAppMock from '../../assets/Publisher_Web_App_Publisher.png'
// import BlackRippedEdgeUp from '../../assets/Black_Ripped_Edge_Up.png'
import BlackRippedEdgeDown from '../../assets/Black_Ripped_Edge_Down.png'
// import black_paint_BG from '../../assets/black_paint_BG.png'
import { ArrowRightOutlined } from '@ant-design/icons'

const publisherBlue = '#0075aa'

export default function OpineForPublishersSection(){
  return(
    <Row 
      justify='center'
      style={{
        position:'relative',
        marginBottom:-230,
        marginTop:-50
      }}>

      <Row
        style={{
          position:'absolute',
          backgroundImage:`url(${BlackRippedEdgeDown})`,
          width:'100%',
          top:-370,
          height: 370,
          backgroundSize:'contain',
          backgroundRepeat: 'no-repeat',
          transform:'scaleX(-1) scaleY(-1)',
        }}
      />
        <Row align='middle' style={{background:'black', overflow:'hidden',paddingTop:75, paddingBottom:75}}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} 
            style={{
              display:'flex', 
              justifyContent:'flex-end',
              zIndex:3,
              paddingTop:0,
              marginBottom: -80
            }}
          >
            <img src={WebAppMock} alt='Opine Publisher Web App' 
              style={{
                maxHeight:800
                }}
              />
          </Col>
          <Col 
            xs={24} 
            sm={22} 
            md={22} 
            lg={{span:22, offset:2}} 
            xl={{span:10, offset:1}} 
            xxl={{span:10, offset:1}} 
            style={{
              padding: 50, 
              paddingBottom:75
            }}
          >
              <Typography.Text 
                style={{
                  // marginTop:30, 
                  color:publisherBlue, 
                  fontSize:20, 
                  fontWeight:300, 
                  lineHeight:2, 
                  letterSpacing:1,
                }}>
                  More than just poll results
              </Typography.Text>

              <Typography.Title 
                style={{
                  textTransform:'uppercase', 
                  color:'white', 
                  fontSize:44,
                  marginTop: 0,
                  // lineHeight:0,
                }}>
                  Opine for Publishers
              </Typography.Title>

              <Typography.Title 
                level={2} 
                style={{
                  fontSize:24, 
                  fontWeight:200, 
                  opacity:0.5,
                  lineHeight:1.75,
                  color:'white'
                }}>
                  Opine products and services enable people to communicate faster and in context as they watch together. Join the community of individuals, universities, broadcast/streaming outlets, news networks, OTT services, sports franchises, journalists and content creators. 
              </Typography.Title>

              <Typography.Link 
                href='https://publisher.opinelive.com/'
                target="_blank"
                style={{
                  color:publisherBlue, 
                  fontSize:18,
                }}
              >
                Go to the Web App <ArrowRightOutlined/>
              </Typography.Link>

          </Col>
        </Row>
        <Row
          style={{
            position:'relative',
            // bottom:-120,
            // marginTop:152,
            backgroundImage:`url(${BlackRippedEdgeDown})`,
            width:'100%',
            height: 357,
            backgroundSize:'contain',
            backgroundRepeat: 'no-repeat',
            zIndex:0,
            // transform:'scaleY(-1) scaleX(-1',
            // backgroundPositionY: 357,
          }}
        />
    
    </Row>
  )
}