import React,{ useContext, useState, useRef } from 'react'
import { FirebaseContext } from '../utils/firebase'

import { Button, Input, Form, message, Row, Col } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
// import sendNodeMail from '../../utils/nodemailer'

import ReactGA from 'react-ga';

export default function SignupModal({setVisible, setConfirmed}){
  const firebase = useContext(FirebaseContext)
  const [form] = Form.useForm();
  const recaptchaRef = useRef();

  const [ validCaptcha, setValidCaptcha ] = useState({
    validateStatus: null, 
    errorMsg: null
  })
  const [ loading, setLoading ] = useState(false)

  function onChange(value) {
    setValidCaptcha({
      ...validateCaptcha(value),
      value,
    });
  }

  function validateCaptcha(value) {
    if (value) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
    return {
      validateStatus: 'error',
      errorMsg: 'CAPTCHA Required',
    };
  }
    
  const onFinish = async values => {
    if(validCaptcha.validateStatus !== 'success'){
      return setValidCaptcha({
        validateStatus: "error", 
        errorMsg: 'CAPTCHA Required'
      })
    }

    console.log('values', values)
    setLoading(true)
    firebase.addToEmailCollection(values.email, values.firstName, values.lastName)
    .then(async res =>{
      if(res){
        // console.log('database success')
          firebase.nodeMailer(values.email, values.firstName, values.lastName)
            .then(res => {
              setConfirmed(true)
              setLoading(false)
              form.resetFields()
              setValidCaptcha(false)
              setVisible(true)
            })
          .catch(err => {
            message.error(`Oops... there was a problem. Please try again.`)
          })
        }else {
          setLoading(false)
          // console.log('database error')
          message.error(`Oops... there was a problem. Please try again.`)
        }
      })
    ReactGA.event({
      category: 'Waitlist',
      action: 'Submitted Info'
    })
  };

  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
    message.error(errorInfo.errorFields[0].errors)
  };


  return(
    <Row justify='center'>
      <Col span={18}>
        <Form
          form={form}
          layout='vertical'
          style={{marginTop:20}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='false'
          name="submit-ref"
        >
          <Form.Item 
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Please input your First Name',
              },
            ]}
          >
            <Input autoComplete='off' size='large' placeholder='First' style={{borderRadius:6, border:'2px black solid', textAlign:'center'}}/>
          </Form.Item>
          <Form.Item 
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please input your Last Name',
              },
            ]}
          >
            <Input autoComplete='off' size='large' placeholder='Last' style={{borderRadius:6, border:'2px black solid', textAlign:'center'}}/>
          </Form.Item>
          <Form.Item 
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input autoComplete='off' size='large' placeholder='Email' style={{borderRadius:6, border:'2px black solid', textAlign:'center'}}/>
          </Form.Item>
          <Form.Item
            validateStatus={validCaptcha.validateStatus}
            help={validCaptcha.errorMsg}
          >
            {/* {validCaptcha.validateStatus !== 'success' 
            ?  */}
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey='6LfKsOIZAAAAAF_2G9qEHBTEX74M64xEXCSC7OGi'
                onChange={onChange}
                rules={[
                  {
                    required: true,
                    message: 'CAPTCHA Required',
                  },
                ]}
              />
            {/* :
              null
            } */}
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type='primary' style={{borderRadius:5, border:'2px black solid', background:'red', margin:'0 auto'}} htmlType='submit'>
              Save My Spot
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}