import { Col, Row, Typography } from "antd";
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css'
import poster from '../../assets/video_poster.png'
import AppStoreBadge from '../../assets/Download_on_the_App_Store_Badge.svg'

export default function VideoSection(){
  return(
    <Row justify='center' style={{position:'relative', marginTop:-100}}>
      <Col 
        xs={22} 
        sm={22} 
        md={20} 
        lg={18} 
        xl={14} 
        style={{
          position:'relative', 
          top:75, 
          width:'100%', 
          zIndex:1, 
          border:'5px solid white', 
          borderRadius:10, 
          boxShadow:'0 3px 15px rgba(0,0,0,0.4)'
        }}>
        <Player 
          playsInline
          poster={poster}
          src="https://firebasestorage.googleapis.com/v0/b/flamelink-cbbaa.appspot.com/o/15_Sec_Promo_Nov_21_2019.mp4?alt=media&token=dc9b78ce-7ea0-4bc0-83eb-2fb502a51b96"
        >
          <BigPlayButton position="center"/>
        </Player>
      </Col>
      <Col 
        span={24}
        style={{
          background:'#d92a1c', 
          padding: 50, 
          display:'flex', 
          flexDirection:'column', 
          alignItems:'center', 
          textAlign:'center', 
          paddingTop: 100
        }}>
        {/* <Col span={6}>
          <Typography.Title level={5} style={{color:'white', fontWeight:200, fontSize:22, opacity:0.99}}>
            Drop the public chats, tweets, grams, and tedious text messages that leave you behind
          </Typography.Title>
        </Col> */}
        <Col 
          span={18}
          style={{
            margin:0,
            padding:0,
          }}
        >
          <Typography.Title 
            level={2} 
            style={{
              color:'white', 
              opacity:0.99, 
              marginBottom:20, 
              paddingBottom:0,
            }}>
            Download Opine and Join in on the Action
          </Typography.Title>
        </Col>
        <Col 
        xs={24} 
        sm={18} 
        md={14} 
        lg={12} 
        xl={8} 
        style={{
          margin:0,
          padding:0
        }}
        >
          <Typography.Text style={{color:'white', fontWeight:200, fontSize:16, opacity:0.99, marginTop:0, paddingTop:0}}>
            If you are looking for a way to keep up with the rapid-fire conversations about short-lived moments in any live event, then buckle in, because you’re about to take in all the opinions you can handle.
          </Typography.Text>
        </Col>
        <Col style={{margin:0, marginTop:40}}>
          {/* <Button 
            ghost 
            shape='round' 
            href='https://apps.apple.com/us/app/opine-live/id1299500673' 
            target='blank'
            >
              Download Opine Now!
            </Button> */}
            <a href='https://apps.apple.com/us/app/opine-live/id1299500673' target="_blank" rel="noreferrer"><img src={AppStoreBadge} alt='Download on the App Store' /></a>
        </Col>
      </Col>
    </Row>
  )
}