import { Result, Button, Row, Col } from 'antd';

export default function SignupSuccess({setVisible}){
  return(
    <Row justify='center' align='middle'>
      <Col>
        <Result
          status="success"
          title="You're In!"
          subTitle="You'll recieve a confimation email shortly."
          extra={[
            <Button type="primary" shape='rounds' key="console" onClick={()=>setVisible(false)}>
              Close
            </Button>
          ]}
        />
      </Col>
    </Row>
  )
}