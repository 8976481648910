import React,{ useState } from 'react'

import { Col, Row, Typography } from "antd";
import Modal from 'antd/lib/modal/Modal';

import background from '../../assets/banner_bg_3.jpg'
import logo from '../../assets/Opine_Logo_Light_BG.svg'
import rippedPaperUnderLeft from '../../assets/RippedPaper_Under_Left.png'
import rippedPaperUnderShadowRight from '../../assets/RippedPaper_UnderShadow_Right.png'
import SignupModal from '../../modals/SignupModal';
import SignupSuccess from '../SignupSuccess/SignupSuccess';
import AppStoreBadge from '../../assets/Download_on_the_App_Store_Badge.svg'

const height = '90vh'

export default function Banner(){
  const [ visible, setVisible ] = useState(false)
  const [ confirmed, setConfirmed ] = useState(false)

  const handleOk = () => {
    setVisible(false)
  }

  return(
    <Row
      style={{
        width:'100%',
        height:height, 
        backgroundImage:`url(${background})`,
        backgroundSize:'cover',
        overflow:'hidden',
      }}
    >
      <Col
        xs={3} sm={6} md={7} lg={8}
        style={{
          position:'relative',
          right:-1,
          backgroundImage:`url(${rippedPaperUnderLeft})`,
          backgroundSize:'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition:'right'
        }}
      />

      <Col
        xs={18} sm={12} md={10} lg={8}
        >
        <Row
        justify='center'
        align='middle'
        style={{ 
          textAlign:'center',
          background:'white',
          height: '100%',
        }}
        >
        <Col span={24}>
          <img src={logo} alt='Opine App' style={{width:'100%'}}/>
          <Col style={{marginTop:20}}>
            <Typography.Text style={{fontSize:18, fontWeight: 300, opacity:0.6}}>
              We're live on the app store!
            </Typography.Text>
          </Col>
          <Col style={{marginTop:20}}>
            <a href='https://apps.apple.com/us/app/opine-live/id1299500673' target="_blank" rel="noreferrer"><img src={AppStoreBadge} alt='Download on the App Store' /></a>
          </Col>
        </Col>
        </Row>
      </Col>

      <Col
        xs={3} sm={6} md={7} lg={8}
        style={{
          position:'relative',
          backgroundImage:`url(${rippedPaperUnderShadowRight})`,
          backgroundSize:'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />

      <Row style={{position:'absolute', width:'100%', bottom:0}}>
        <Col 
          style={{
            position: 'relative',
            width:'100%',
            border: '2px solid white',
            transform:'rotate(0.1deg)'
          }}
        />
        <Col 
          style={{
            position: 'relative',
            width:'100%',
            border: '2px solid black',
            transform:'rotate(-0.5deg)'
          }}
        />
        <Col 
          style={{
            position: 'relative',
            width:'100%',
            border: '2px solid black',
            transform:'rotate(0.5deg)'
          }}
        />
      </Row>
      <Modal
        visible={visible}
        title="Beta Access Request"
        onOk={handleOk}
        onCancel={handleOk}
        footer={null}
      >
        {confirmed 
          ? 
            <SignupSuccess setVisible={setVisible}/> 
          : 
            <SignupModal setVisible={setVisible} setConfirmed={setConfirmed}/>
        }
      </Modal>
    </Row>
  )
}

