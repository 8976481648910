import Banner from '../Home/BannerV3'
import WhatIsOpineSection from '../Home/WhatIsOpineSection'
import OpineForPublishersSection from '../Home/OpineForPublishersSection'
import FAQSection from '../Home/FAQSection'
// import ContactSection from '../Home/ContactSection'
import Layout, { Content } from 'antd/lib/layout/layout';
import VideoSection from '../Home/VideoSection'
export default function Home(){
  return(
    <Layout>
      <Content style={{background:'white', position:'relative'}}>
        <Banner/>
      </Content>
      <Content style={{ background:'white'}}>
        <WhatIsOpineSection/>
        <OpineForPublishersSection/>
        <FAQSection/>
        <VideoSection/>
        {/* <ContactSection/> */}
      </Content>
    </Layout>
  )
}