import { useState } from 'react'
import logo from '../assets/Opine_Logo_Light_BG.svg'
import { Row, Col, Typography, Button, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import AppStoreBadge from '../assets/Download_on_the_App_Store_Badge.svg'
import { useHistory } from 'react-router-dom'

export default function HeaderBar(){
  const history = useHistory()
  const [ drawerOpen, setDrawerOpen ] = useState(false)

  return(
    <Row justify='space-between' align='middle'>
      <Col>
        <img src={logo} alt="Opine Live" style={{height:45, cursor:'pointer'}} onClick={()=>history.push('/')}/>
      </Col>

      <Col xs={0} sm={0} md={18}>
        <Row justify='end' align='middle'>
          <Typography.Link href='https://publisher.opinelive.com/' target="_blank" style={{marginRight:10, color:'black'}}>
            Publisher Access
          </Typography.Link>
          <a href='https://apps.apple.com/us/app/opine-live/id1299500673' target="_blank" rel="noreferrer"><img src={AppStoreBadge} alt='Download on the App Store' /></a>
          {/* <Button shape='round' icon={<AppleOutlined/>} href='https://apps.apple.com/us/app/opine-live/id1299500673' target="_blank">Download on the App Store</Button> */}
        </Row>
      </Col>

      <Col xs={2} sm={2} md={0} lg={0}>
        <Button type='text' size='large' icon={<MenuOutlined/>} onClick={() => setDrawerOpen(true)}/>
      </Col>

      <Drawer
        title="Opine Live"
        placement="right"
        closable={true}
        onClose={()=>setDrawerOpen(false)}
        visible={drawerOpen}
        width='75%'
      >
        <Row >
          <Col span={24} style={{display:'flex', justifyContent:'center'}}>
            {/* <Button type='default' block size='large' icon={<AppleOutlined/>} href='https://apps.apple.com/us/app/opine-live/id1299500673' target="_blank">
              Download on the App Store
            </Button> */}
            <a href='https://apps.apple.com/us/app/opine-live/id1299500673' target="_blank" rel="noreferrer"><img src={AppStoreBadge} alt='Download on the App Store' /></a>
          </Col>
          <Col span={24}>
            <Button block type='default' size='large' href='https://publisher.opinelive.com/' target="_blank" style={{marginTop:10}}>
              Publisher Access
            </Button>
          </Col>
        </Row>
      </Drawer>
    </Row>
  )
}