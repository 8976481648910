import React from 'react'
import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import moment from 'moment'

const FirebaseContext = React.createContext(null)
export { FirebaseContext }

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

class Firebase {
  constructor(){
    // console.log('app', app)
    app.initializeApp(config)

    this.auth = app.auth()
    this.database = app.database()
    this.storage = app.storage()
    this.firestore = app.firestore().doc('versions/v1-prod')
    this.functions = app.functions()
    // if(window.location.href === 'http://localhost:3000/test'){
    //   this.firestore.useEmulator('localhost', 8080)
    // }
  }

  nodeMailer = (email, firstName, lastName) => {
    let mailer = this.functions.httpsCallable('sendNodeMail')
    return mailer({
      email:email,
      firstName: firstName,
      lastName: lastName
    })
    .then(res => {
      console.log('res', res)
      return res
    }).catch(err => {
      console.log('err', err)
      return err
    })
  }

  addToEmailCollection = async (email, firstName, lastName) => {
    console.log('addEmailCollection')
    let ref = this.firestore.collection('web_referrals')
    
    let newEmailRes = ref.add({
      firstName,
      lastName,
      email,
      referring_user: 'web',
      date: new Date(),
    })

    return newEmailRes
  }
    
  sendEmail = email => {
    let ref = this.firestore.collection('email_trigger')
    
    let sendEmailRes = ref.add({
      to: 'tcarter@opineinc.com',
      date: new Date(),
      message: {
        subject: `${email} has signed up!`,
        text: `${email} was just added to the wait list from opinelive.com!`,
      }
    })

    return sendEmailRes
  }


  getEventAnalytics = async (eventId) => {
    let event
    let feedArr
    let userArr = []
    let responseCount
    let promptCount

    event = await this.getEventDetails(eventId)

    // 1. GET ALL EVENT FEED DOCUMENTS AND CALCULATE CORRECT PROMPT COUNT FOR EACH FEED
    feedArr = await this.getAllEventFeeds(eventId)
    console.log('feedArr:', feedArr)

    // 2. GET ALL USERS SUBSCRIBED TO ALL FEEDS
    userArr = await this.getFeedSubscribers(feedArr)
    console.log('userArr:', userArr)

    // 3. GET RESPONSE COUNT
    responseCount = await this.firestore.collection('responses')
    .where('eventId', '==', eventId)
    .get()
    .then(snapshot => {
      let newArr= []
      snapshot.forEach(doc => {
        newArr.push(doc.data())
      })
      return newArr
    })
    console.log('responseCount:', responseCount)

    // 3. GET RESPONSE COUNT
    promptCount = await this.firestore.collection('prompts')
    .where('eventId', '==', eventId)
    .get()
    .then(snapshot => {
      let newArr= []
      snapshot.forEach(doc => {
        newArr.push(doc.data())
      })
      return newArr
    })
    console.log('promptcount:', promptCount)


  }

  getEventDetails = async (eventId) => {
    return this.firestore.collection('events').doc(eventId).get().then(doc=>{
      if(doc.exists){
        return doc.data()
      }
    })
  }
  
  getAllEventFeeds = async (eventId) => {
    const promises = []
    let correctedPromptCount
    let query = await this.firestore.collection('feeds')
    .where('eventId', '==', eventId)
    .get()
    query.forEach(doc=>{
      let feed = doc.data()
      feed = {...feed, promptCount:0}
      promises.push(feed)
    })
    await Promise.all(promises.map(async feedDoc => {
      let newFeedArr = await this.getCorrectedPromptCount(feedDoc, promises)
      correctedPromptCount = newFeedArr
    }))
    return correctedPromptCount
  }
  
  getCorrectedPromptCount = async (feed, feedArr) => {
    let feedPromptCount = 0
    await this.firestore.collection(`prompts`)
      .where('feedId', '==', feed.id)
      .get()
      .then( snap => {
        snap.forEach(doc => {
          if(doc.exists){
            feedPromptCount += 1
          } else { 
            feedPromptCount += 0
          }
          return
        })
      })
      .catch(err => {
        console.log('Error:', err)
        return err
      })
    let index = feedArr.findIndex(feedDoc => feedDoc.id === feed.id)
    if(index === -1){
      console.log('not found')
    } else {
      feedArr[index].promptCount += feedPromptCount
    }
    return feedArr
  }
  
  getFeedSubscribers = async (feedArr) => {  
    let newUserArr = []
    for (const feed of feedArr){
      const query = await this.firestore.collection(`feeds/${feed.id}/users`).get()
      query.forEach(doc => {
        if(doc.exists){
          let user = doc.data()
          newUserArr.push({...user, id:doc.id, eventPromptsReceived:feed.promptCount})
        }
        return
      })
    }
    console.log('newUserArr', newUserArr)
    let mergedUsers = []
    newUserArr.map( newUser => {
      let index = mergedUsers.findIndex(mergedUser => {
        return mergedUser.id === newUser.id
      })
      if( index === -1){
        mergedUsers.push(newUser)
        return 
      } else {
        return mergedUsers[index].eventPromptsReceived += newUser.eventPromptsReceived
      }
    })
    console.log('mergedUsers', mergedUsers)
  
    return mergedUsers
  }
  
  getUserEventData = async (userId, eventId) => {
    try{
      let sumIntensity = 0
      let intensityCount = 0
      let responseCount = 0
      let intensity = 0
  
      const query = await this.firestore.collection('responses')
        .where('eventId', '==', eventId)
        .where('userId', '==', userId)
        .get()
  
      query.forEach(doc=>{
        if(doc.exists){
          let response = doc.data()
          responseCount += 1
          if(response.intensity > 0){
            sumIntensity += response.intensity
            intensityCount += 1
          }
        } else {
          return 'no doc'
        }
      })
  
      if(sumIntensity){
        intensity = sumIntensity/intensityCount
      }
      let data = { intensity, responseCount }
      return data
    }
    catch (err) {
      console.log('error', err)
      return err
    }
  }

  getWeeksFeedsWithUsers = async () => {
    const start = moment().subtract(7,'day').startOf('day').utc().format()
    const end = moment().subtract(1,'day').endOf('day').utc().format()
    let activeFeedsArr = []
    let activeUsersArr = []

    await this.firestore.collection('feeds')
      .where('eventScheduledTime', '>=', start)
      .where('eventScheduledTime', '<=', end)
      .get()
      .then(snap => {
        snap.forEach(docRef => {
          let doc = docRef.data()
          if(doc.userCount > 0){
            activeFeedsArr.push(doc)
          }
        })
      })

    await Promise.all(activeFeedsArr.map(feedDoc => {
      return this.firestore.collection('feeds').doc(feedDoc.id).collection('users').get().then(snap=>{
        return snap.forEach(docRef => {
          let doc = docRef.data()
          let newDoc = {
            userId:docRef.id, 
            gender:doc.gender,
            birthYear: doc.yearOfBirth,
            zipcode: doc.zipCode,
            eventData: [{
              eventName: feedDoc.eventName,
              eventId:feedDoc.eventId, 
              feedData:[{
                feedId: feedDoc.id, 
                feedTitle: feedDoc.title, 
                feedPromptCount: feedDoc.promptCount
              }]
            }]
          }
          activeUsersArr.push(newDoc)
        })
      })
    }))

    let uniqueUsers = []

    activeUsersArr.map(user => {
      let index = uniqueUsers.findIndex(uniqueUser => {
        return user.userId === uniqueUser.userId
      })
      if( index === -1){
        uniqueUsers.push(user)
        return 
      } else {
        return uniqueUsers[index].eventData.push(user.eventData[0])
      }
    })

    let userArr = uniqueUsers.map(user => {
      let uniqueEvents = []
      let feedCount = 0
      user.eventData.forEach(event => {
        let eventIndex = uniqueEvents.findIndex(uniqueEvent => {
          return uniqueEvent.eventId === event.eventId
        })
        if( eventIndex === -1){
          uniqueEvents.push(event)
          feedCount += 1
        } else {
          feedCount += 1
          uniqueEvents[eventIndex].feedData.push(event.feedData[0])
        }
      })
      user = {...user, eventData: uniqueEvents, eventCount: uniqueEvents.length, feedCount}
      return user
    })

    let res = await Promise.all(userArr.map(async user => {
      let eventRes = await Promise.all(user.eventData.map(async event => {
        let eventResponseCount = 0
        let feedRes = await Promise.all(event.feedData.map(async feed => {
          let feedResponses = []
          await this.firestore.collection('responses')
            .where('feedId', '==', feed.feedId)
            .where('userId', '==', user.userId)
            .get()
            .then(snap => {
              snap.forEach(feedDocRef => {
                const doc = feedDocRef.data()
                feedResponses.push(doc)
              })
          })
          feed = {...feed, responses: feedResponses, responseCount: feedResponses.length}
          eventResponseCount += feedResponses.length
          return feed
        }))
        let newEvent = {...event, feedData: feedRes, eventResponseCount: eventResponseCount}
        return newEvent
      }))
      // console.log('eventRes', eventRes)
      let newUser = {...user, eventData: eventRes}
      return newUser
    }))

    let dataStr = JSON.stringify(userArr);
    let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);

    let exportFileDefaultName = 'data.json';
    console.log('res', res)
  }



}


const firebaseInit = ({ children }) => {
  return (
    <FirebaseContext.Provider value={ new Firebase() }>
      { children }
    </FirebaseContext.Provider>
  )
}

export default firebaseInit