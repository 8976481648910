import React from 'react'

import { Col, Row, Typography, Divider } from "antd";

export default function FAQSection(){
  return(
    <section style={{margin:50}}>
      <Row justify='center'>
        <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={6} style={{textAlign:'center'}}>
          <Typography.Text strong style={{fontSize:18, color:'red', }}>FAQ</Typography.Text>
          <Typography.Title style={{margin:0, textTransform:'uppercase'}}>Let’s make some things clear</Typography.Title>
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={8} xl={18} xxl={18}> */}
          <Row gutter={[30,30]} style={{marginTop:75}}>
              {faqContent.map(card => (
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={{span: 6, offset: 1}} key={card.title}>
                  <Typography.Title level={4}>{`Q ${card.title}`}</Typography.Title>
                  <Col span={4} style={{padding:0}}>
                    <Divider style={{borderColor:'red'}}/>
                  </Col>
                  <Typography.Paragraph >{card.text}</Typography.Paragraph>
                </Col>
              ))}
          </Row>
        {/* </Col> */}
      </Row>
    </section>
  )
}



const faqContent = [
  {
    title:'How does Opine work?',
    text:'Users form one or many Huddles (groups). They select the type of information that interests them for a specific event. Information is published to users based on micro-moments in that live event. In real-time, users vote their opinion on the published statement and how intensely they feel about it. The opinion analytics and intensity of the Huddle(s) are provided back to each individual in that Huddle. Users see the vote of each user in their Huddle(s).'
  },
  {
    title:'What are opinion analytics?',
    text:`Opinion analytics are statistical representations of people's opinions about a specific topic. They can also include the intensity of that opinion. Opine provides to users these opinion analytics based on their Huddles, for each statement and question to which they respond.`
  },
  {
    title:'What is a micromoment in a live event?',
    text:`Live events, such as a game, political debate, or awards show move along quickly. Each play, phrase or action causes emotions and opinions to arise. It is these moments that come and go so quickly that it is difficult to socialize in that time-frame or share your views about it by typing words. Collectively, these instant and fleeting activities are the micro-moments that make up the event. It is inside the context and confines of these micromoments that Opine is so enjoyable.`
  },
  {
    title:'Is Opine really new or just a rehash of other social media applications?',
    text:`Opine patented technology is built to uniquely meet the demands of micro-moment socialization during live events. Opine is the first opinion analytics platform for communication during live events.`
  },
  {
    title:'How is Opine different than other ways I watch or interact with others during a live event?',
    text:`Never have the micro-moments of an event been the driver of social engagement like they are with Opine. This is because Opine is built specifically to facilitate socializing at the speed of an event. While other social forms take time to type out words, Opine turns opinions of a few or many users into a statistic that is quickly understood. That foundation of understanding can then be used for conversation.`
  },
  {
    title:'Can I use Opine while watching an event alone?',
    text:`Whether watching an event alone at home or in a crowded room, you can be connected with others across the world via the play-by-play moments of an event. Opine is built as a safe place to interact with groups of people you know. It also allows you to enjoy watching a game alone without anyone, while still interacting with the micro-moments of the live event.`
  },
  {
    title:'Can anyone be a publisher?',
    text:`Yes. Whether you want to talk sneakers, play-by-play or use the event as a topic of jokes. There is an audience out there who is interested in what you have to say - and vote on it! You can simultaneously be a publisher and user with Huddles.`
  },
  {
    title:'Is Opine just for Sports?',
    text:`No. Opine is for all live events, including political debates, award and TV shows, e-sports and other events, like recitals or performances.`
  },
  {
    title:'What opportunities are there to engage or partner with Opine?',
    text:`Opine is focused on audience enjoyment and engagement. Because of this, partners across the sports and entertainment industries such as universities, pro teams, leagues, federations and media companies look to participate with Opine. Publishers as well as Opine partners engage with viewers across, mobile, OTT, linear broadcasts, web streaming and other content engagement and distribution methods.`
  },
]