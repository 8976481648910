import React from 'react'
import { Row, Col, Typography, Card } from "antd";
import { PhoneOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons'

export default function Contact(){
  // const [ name, setName ] = useState()
  // const [ email, setEmail ] = useState()
  // const [ message, setMessage ] = useState()

  // const onNameChange= (event) =>{
  //   setName(event.target.value)
  // }

  // const onEmailChange = (event) => {
  //   setEmail(event.target.value)
  // }

  // const onMessageChange = (event) => {
  //   setMessage(event.target.value)
  // }

  // const handleSubmit = (event) => {
  // }

  return(
    <section style={{ height:'100%', width:'100%', padding:50, background:'white'}}>
      {/* <Divider/> */}
      <Row justify='center'>
        <Col span={24} style={{textAlign:'center'}}>
          {/* <Typography.Text strong style={{fontSize:18, color:'red', }}>FAQ</Typography.Text> */}
          <Typography.Title style={{margin:0, textTransform:'uppercase'}}>Contact Us</Typography.Title>
        </Col>
      </Row>
      <Row align='middle' gutter={[16,16]} style={{marginTop:50}}>
        <Col xs={24} md={{span:14, offset:6}} lg={{span:12, offset:6}} xl={{span:8, offset:8}}>
          <Card title='Contact Info'>
            <Col>
              <PhoneOutlined/><br/>
              <Typography.Link href='tel:385.484.8483'>385.484.8483</Typography.Link>
            </Col>
            <Col>
              <MailOutlined/><br/>
              <Typography.Link href='mailto:info@opineinc.com'> Info@opineinc.com</Typography.Link>
            </Col>
            <Col>
              <EnvironmentOutlined/>
              <address>
                815 West 1250 South, <br/>
                OREM, UT 84058
              </address>
            </Col>
          </Card>
        </Col>

        {/* <Col span={6} offset={2}>
          <Typography.Title level={2}>Contact Us</Typography.Title>
          <Form
            layout='vertical'
            name="basic"
          >
            <Form.Item name="name">
              <Input autoComplete='off' size='large' placeholder='Name' style={{borderRadius:6, border:'2px black solid'}}/>
            </Form.Item>
            <Form.Item name="email">
              <Input autoComplete='off' size='large' placeholder='Email' type='email' style={{borderRadius:6, border:'2px black solid'}}/>
            </Form.Item>
            <Form.Item name="message">
              <Input.TextArea autoSize={{minRows: 3}} placeholder='Message' type='textarea' style={{borderRadius:6, border:'2px black solid'}}/>
            </Form.Item>
            <Form.Item>
              <Button type='primary' size='large' style={{borderRadius:5, border:'2px black solid', background:'red'}}>
                Send
              </Button>
            </Form.Item>
          </Form>
        </Col> */}
    </Row>
    </section>
  )
}