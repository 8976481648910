import './App.css'
import HeaderBar from './components/HeaderBar'
import Layout, { Footer, Header, Content } from 'antd/lib/layout/layout';

import ReactGA from 'react-ga';
import routes from './routes'
import { Row, Col, Typography } from 'antd';
ReactGA.initialize("UA-150078443-1");
ReactGA.pageview(window.location.pathname + window.location.search);

var date = new Date();
var year = date.getFullYear();

function App() {
  return (
    // <Layout style={{height:'100vh'}}>
    <Layout style={{height:'100vh'}}>
      <Header style={{zIndex:1, background:'white', boxShadow:'0 3px 15px rgba(0,0,0,0.4)'}}>
        <HeaderBar/>
      </Header>
        {/* <Banner/> */}
      <Content style={{ position:'relative'}}>
        {routes}
        <Footer style={{textAlign:'center'}}>
          <Row gutter={12} justify='center'>
          <Col>
            Opine Inc. ©{year}
          </Col>
          <Col style={{opacity:0.2}}>
            |
          </Col>
          <Col>
            <Typography.Link href='/privacy-policy'>Privacy Policy</Typography.Link>
          </Col>
          <Col style={{opacity:0.2}}>
            |
          </Col>
          <Col>
            <Typography.Link href='/contact'>Contact</Typography.Link>
          </Col>
          </Row>
        </Footer>
      </Content>
    </Layout>
  );
}

export default App;